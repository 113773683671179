const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.gromit.forwoodsafety.com',
    APP_CLIENT_ID: '26ss7pcbqrdlhlkr8hnr0h5gga',
    USER_TOKEN_URL: 'https://oim80dqpm8.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://wdq2oe8cvh.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.dev.gromit.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.dev.gromit.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.dev.gromit.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.gromit.forwoodsafety.com',
    WEBSOCKET: 'wss://4ln3yeq9z7.execute-api.us-west-2.amazonaws.com/dev'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;